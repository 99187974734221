import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { AppRoutingModule } from './app-routing.module';
import { InputDataComponent } from './input-data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RippleModule } from 'primeng/ripple';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PplusInputSwitchComponent } from './pplus-input-switch/pplus-input-switch.component';

@NgModule({
  declarations: [
    InputDataComponent,
    PplusInputSwitchComponent,
    InputDataComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    SelectButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    RippleModule,
    FormsModule,
    ReactiveFormsModule
  ],
  bootstrap: [InputDataComponent]
})
export class InputDataModule { }
