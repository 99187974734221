<div class="d-flex align-items-center fixed-top-right">
  <div class="ml-auto">
    <button (click)="onButtonClick('es')" class="btn btn-link">
      <img src="assets/images/spain.png" style="width: 20px; height: 20px" />
    </button>
    <button (click)="onButtonClick('por')" class="btn btn-link">
      <img src="assets/images/portugal.png" style="width: 20px; height: 20px" />
    </button>
  </div>
</div>

<div *ngIf="language === 'es'">
  <nav class="navbar navbar-expand-lg static-top mt-3">
    <div class="container heading">
      <h1>
        <a class="navbar-brand" href="">
          <img src="assets/images/orducalc-logo.png" alt="ORDUCALCPRO LOGO" />
        </a>
      </h1>
      <h5 [hidden]="showKit">
        A un click de la solución que necesitas. Dimensionador para tus
        proyectos.
      </h5>
    </div>
  </nav>
  <div [hidden]="showKit">
    <div class="container-fluid">
      <div class="row mr-0 ml-0">
        <div class="col-md-5 p-0">
          <p class="m-2">
            Te damos la bienvenida a <span class="bold-text">ORDUCALC</span
            ><span class="org-text">PRO</span>, la herramienta desarrollada por
            Suministros Orduña para ayudarte a definir la mejor solución
            paquetizada para la necesidad energética de tu proyecto de forma
            rápida y sencilla.
          </p>
          <img class="mw-100" src="assets/images/house.png" alt="young master" />
        </div>
        <div class="col-md-7 consume-section">
          <form [formGroup]="inputDataForm" (ngSubmit)="onSubmit()">
            <ul>
              <li>
                <div class="row">
                  <div class="col">
                    ¿Qué porcentaje aproximado de ahorro en la factura eléctrica
                    busca el cliente? ¿30-40% (sin acumulación) o 80-90% (con
                    acumulación)?
                  </div>
                  <div class="col">
                    <app-pplus-input-switch
                      [options]="saveOptionsEs"
                      [rightIcon]="saveOptionsEs[1].icon"
                      [leftIcon]="saveOptionsEs[0].icon"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="saveOption"
                    >
                    </app-pplus-input-switch>
                  </div>
                </div>
              </li>
              <hr />
              <li>
                <div class="row">
                  <div class="col">
                    ¿El cliente dispone de una instalación eléctrica trifásica o
                    monofásica?
                  </div>
                  <div class="col">
                    <app-pplus-input-switch
                      [options]="powerOptions"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="powerOption"
                    ></app-pplus-input-switch>
                  </div>
                </div>
              </li>
              <hr />
              <li>
                <div class="row">
                  <div class="col">¿Cuál es su consumo aproximado en los meses de verano? (kWh/mes)​</div>
                  <div class="col">
                    <p-dropdown
                      [options]="summerConsumption"
                      optionLabel="desc"
                      optionValue="value"
                      formControlName="summerConsumption"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="col text-center mt-5">
                <button
                  pButton
                  pRipple
                  type="submit"
                  [disabled]="!inputDataForm.valid"
                  label="Descubre tu Pack"
                  class="p-button-rounded big-btn"
                ></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="language === 'por'">
  <nav class="navbar navbar-expand-lg static-top mt-3">
    <div class="container heading">
      <h1>
        <a class="navbar-brand" href="">
          <img src="assets/images/orducalc-logo.png" alt="ORDUCALCPRO LOGO" />
        </a>
      </h1>
      <h5 [hidden]="showKit">
        Um clique para a solução que você precisa. Dimensionador para seus
        projetos.
      </h5>
    </div>
  </nav>
  <div [hidden]="showKit">
    <div class="container-fluid">
      <div class="row ml-0 mr-0">
        <div class="col-md-5 p-0">
          <p class="m-2">
            Damos as boas-vindas ao <span class="bold-text">ORDUCALC</span
            ><span class="org-text">PRO</span>, a ferramenta desenvolvida pela
            Suministros Orduña para ajudá-lo a definir a melhor solução em
            pacotes para as necessidades energéticas do seu projeto de forma
            rápida e simples.
          </p>
          <img class="mw-100" src="assets/images/house.png" alt="young master" />
        </div>
        <div class="col-md-7 consume-section">
          <form [formGroup]="inputDataForm" (ngSubmit)="onSubmit()">
            <ul>
              <li>
                <div class="row">
                  <div class="col">
                    Qual a porcentagem aproximada de economia na fatura elétrica
                    que o cliente está buscando? 30-40% (sem acumulação) ou
                    80-90% (com acumulação)?
                  </div>
                  <div class="col">
                    <app-pplus-input-switch
                      [options]="saveOptionsPt"
                      [rightIcon]="saveOptionsPt[1].icon"
                      [leftIcon]="saveOptionsPt[0].icon"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="saveOption"
                    >
                    </app-pplus-input-switch>
                  </div>
                </div>
              </li>
              <hr />
              <li>
                <div class="row">
                  <div class="col">
                    O cliente possui uma instalação elétrica trifásica ou
                    monofásica?
                  </div>
                  <div class="col">
                    <app-pplus-input-switch
                      [options]="powerOptions"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="powerOption"
                    ></app-pplus-input-switch>
                  </div>
                </div>
              </li>
              <hr />
              <li>
                <div class="row">
                  <div class="col">Qual é o seu consumo aproximado nos meses de verão? (kWh/mês)​</div>
                  <div class="col">
                    <p-dropdown
                      [options]="summerConsumption"
                      optionLabel="desc"
                      optionValue="value"
                      formControlName="summerConsumption"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="col text-center mt-5">
                <button
                  pButton
                  pRipple
                  type="submit"
                  [disabled]="!inputDataForm.valid"
                  label="Descubra seu pack"
                  class="p-button-rounded big-btn"
                ></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
