import {
  Component,
  Injector,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { FormBuilder, Validators } from '@angular/forms';
import { lang } from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './input-data.component.html',
  styleUrls: ['./input-data-component.scss'],
})
export class InputDataComponent implements OnInit {
  @ViewChild('kitContainer', { static: true, read: ViewContainerRef })
  kitContainer!: ViewContainerRef;

  @ViewChild('OrduEasy1.5M', { static: true, read: TemplateRef })
  OrduEasy15M!: TemplateRef<any>;
  @ViewChild('OrduEasy2M', { static: true, read: TemplateRef })
  OrduEasy2M!: TemplateRef<any>;
  @ViewChild('OrduEasy3M', { static: true, read: TemplateRef })
  OrduEasy3M!: TemplateRef<any>;
  @ViewChild('OrduEasy42M', { static: true, read: TemplateRef })
  OrduEasy42M!: TemplateRef<any>;
  @ViewChild('OrduEasy5M', { static: true, read: TemplateRef })
  OrduEasy5M!: TemplateRef<any>;
  @ViewChild('OrduEasy6M', { static: true, read: TemplateRef })
  OrduEasy6M!: TemplateRef<any>;
  @ViewChild('OrduEasy85M', { static: true, read: TemplateRef })
  OrduEasy85M!: TemplateRef<any>;
  @ViewChild('OrduEasy10M', { static: true, read: TemplateRef })
  OrduEasy10M!: TemplateRef<any>;

  @ViewChild('OrduEasyS2M', { static: true, read: TemplateRef })
  OrduEasyS2M!: TemplateRef<any>;
  @ViewChild('OrduEasyS3M', { static: true, read: TemplateRef })
  OrduEasyS3M!: TemplateRef<any>;
  @ViewChild('OrduEasyS4M', { static: true, read: TemplateRef })
  OrduEasyS4M!: TemplateRef<any>;
  @ViewChild('OrduEasyS5M', { static: true, read: TemplateRef })
  OrduEasyS5M!: TemplateRef<any>;
  @ViewChild('OrduEasyS6M', { static: true, read: TemplateRef })
  OrduEasyS6M!: TemplateRef<any>;

  @ViewChild('OrduReady5T', { static: true, read: TemplateRef })
  OrduReady5T!: TemplateRef<any>;
  @ViewChild('OrduReady8T', { static: true, read: TemplateRef })
  OrduReady8T!: TemplateRef<any>;
  @ViewChild('OrduReady10T', { static: true, read: TemplateRef })
  OrduReady10T!: TemplateRef<any>;

  @ViewChild('OrduEasyS5T', { static: true, read: TemplateRef })
  OrduEasyS5T!: TemplateRef<any>;
  @ViewChild('OrduEasyS6T', { static: true, read: TemplateRef })
  OrduEasyS6T!: TemplateRef<any>;
  @ViewChild('OrduEasyS8T', { static: true, read: TemplateRef })
  OrduEasyS8T!: TemplateRef<any>;
  @ViewChild('OrduEasyS10T', { static: true, read: TemplateRef })
  OrduEasyS10T!: TemplateRef<any>;

  @ViewChild('OrduEasy4T', { static: true, read: TemplateRef })
  OrduEasy4T!: TemplateRef<any>;
  @ViewChild('OrduEasy5T', { static: true, read: TemplateRef })
  OrduEasy5T!: TemplateRef<any>;
  @ViewChild('OrduEasy6T', { static: true, read: TemplateRef })
  OrduEasy6T!: TemplateRef<any>;
  @ViewChild('OrduEasy7T', { static: true, read: TemplateRef })
  OrduEasy7T!: TemplateRef<any>;
  @ViewChild('OrduEasy8T', { static: true, read: TemplateRef })
  OrduEasy8T!: TemplateRef<any>;
  @ViewChild('OrduEasy10T', { static: true, read: TemplateRef })
  OrduEasy10T!: TemplateRef<any>;

  @ViewChild('OrduStorage3M', { static: true, read: TemplateRef })
  OrduStorage3M!: TemplateRef<any>;
  @ViewChild('OrduStorage36M', { static: true, read: TemplateRef })
  OrduStorage36M!: TemplateRef<any>;
  @ViewChild('OrduStorage5M', { static: true, read: TemplateRef })
  OrduStorage5M!: TemplateRef<any>;
  @ViewChild('OrduStorage6M', { static: true, read: TemplateRef })
  OrduStorage6M!: TemplateRef<any>;
  @ViewChild('OrduStorageS5T', { static: true, read: TemplateRef })
  OrduStorageS5T!: TemplateRef<any>;
  @ViewChild('OrduStorageS6T', { static: true, read: TemplateRef })
  OrduStorageS6T!: TemplateRef<any>;
  @ViewChild('OrduStorageS8T', { static: true, read: TemplateRef })
  OrduStorageS8T!: TemplateRef<any>;
  @ViewChild('OrduStorageS10T', { static: true, read: TemplateRef })
  OrduStorageS10T!: TemplateRef<any>;

  @ViewChild('NotFound', { static: true, read: TemplateRef })
  NotFound!: TemplateRef<any>;
  @ViewChild('Surface', { static: true, read: TemplateRef })
  Surface!: TemplateRef<any>;

  title = 'OrducalcPro';
  imagename = 'contactanos';
  struct = 'coplanar'; // Trisole o coplanar
  numpanels = 5;
  numRows = 1;
  kitSurfaceCoplanar = 0;
  kitSurfaceTrisole = 0;
  kitShopUrl = '';
  structCoplanarShopUrl = '';
  structTrisoleShopUrl = '';
  language: string = 'es';

  showKit = false;

  summerConsumption: any[];

  //  value1: string = "off";
  powerOptions: any[];
  saveOptionsEs: any[];
  saveOptionsPt: any[];
  installationOptions: any[];
  inputDataForm: any;
  formBuilder: FormBuilder;

  constructor(private primengConfig: PrimeNGConfig, injector: Injector) {
    this.formBuilder = injector.get(FormBuilder);

    this.inputDataForm = this.formBuilder.group({
      summerConsumption: [200, Validators.required],
      powerOption: ['', Validators.required],
      saveOption: ['', Validators.required],
      installOption: [''],
    });

    this.summerConsumption = [
      { desc: '200 - 300', value: 200 },
      { desc: '300 - 400', value: 300 },
      { desc: '400 - 600', value: 400 },
      { desc: '600 - 800', value: 600 },
      { desc: '800 - 1000', value: 800 },
      { desc: '1000 - 1200', value: 1000 },
      { desc: '1200 - 1500', value: 1200 },
      { desc: '1500 - 1800', value: 1500 },
      { desc: 'Más de 1800', value: 1800 },
    ];

    this.powerOptions = [
      { label: 'Monofásico', value: 'monofasico' },
      { label: 'Trifásico', value: 'trifasico' },
    ];
    this.saveOptionsEs = [
      { label: '30-40% (con acumulación)', value: 1, icon: 'fas fa-sun' },
      {
        label: '80-90% (sin cacumulación)',
        value: 2,
        icon: 'fas fa-battery-half',
      },
    ];
    this.saveOptionsPt = [
      { label: '30-40% (com acumulação)', value: 1, icon: 'fas fa-sun' },
      {
        label: '80-90% (sem acumulação)',
        value: 2,
        icon: 'fas fa-battery-half',
      },
    ];
    this.installationOptions = [
      { label: 'Sí', value: true, icon: 'fa fa-sun-o' },
      { label: 'No', value: false, icon: 'pi pi-align-right' },
    ];
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  onSubmit() {
    this.showTheKit();
  }

  showTheKit() {
    this.getKit();
  }

  getKit() {
    let template: TemplateRef<any> = this.NotFound;
    this.numRows = 0;

    // Ahorro factura 30-40
    if (this.inputDataForm.value.saveOption === 1) {
      if (this.inputDataForm.value.powerOption === 'monofasico') {
        // Preparado para bateria
        switch (this.inputDataForm.value.summerConsumption) {
          case 200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://suministrosorduna.com/es/shop/product/pack-ordueasy-s-2-m-17944?search=Pack+OrduEasy-S+2+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://suministrosorduna.com/pt/shop/product/pack-ordueasys-2-m-17944?page=4&category=83#attr=';
            }
            break;
          case 300:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://suministrosorduna.com/es/shop/product/pack-ordueasy-s-2-m-17944?search=Pack+OrduEasy-S+2+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://suministrosorduna.com/pt/shop/product/pack-ordueasys-2-m-17944?page=4&category=83#attr=';
            }
            break;
          case 400:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-3-m-17945?search=Pack+OrduEasy-S+3+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-3-m-17945?search=Pack+OrduEasy-S+3+M#attr=';
            }
            break;
          case 600:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-4-m-17946?search=Pack+OrduEasy-S+4+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-4-m-17946?search=Pack+OrduEasy-S+4+M#attr=';
            }
            break;
          case 800:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-m-17947?search=Pack+OrduEasy-S+5+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-5-m-17947?search=Pack+OrduEasy-S+5+M#attr=';
            }
            break;
          case 1000:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            break;
          case 1200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            break;
          case 1500:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            break;
          case 1800:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-6-m-17948?search=Pack+OrduEasy-S+6+M#attr=';
            }
            break;
          default:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
        }
      }
      if (this.inputDataForm.value.powerOption === 'trifasico') {
        switch (this.inputDataForm.value.summerConsumption) {
          case 200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            break;
          case 300:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            break;
          case 400:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            break;
          case 600:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            break;
          case 800:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-5-t-17949?search=Pack+OrduEasy-S+5+T#attr=';
            }
            break;
          case 1000:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-6-t-17950?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-6-t-17950?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            break;
          case 1200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-8-t-17951?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasys-8-t-17951?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            break;
          case 1500:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordueasy-s-10-t-17952?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordueasy-s-10-t-17952?page=2&search=Pack+OrduEasy-S+6+T#attr=';
            }
            break;
          case 1800:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
          default:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
        }
      }
    }

    // Ahorro factura 80-90
    if (this.inputDataForm.value.saveOption === 2) {
      if (this.inputDataForm.value.powerOption === 'monofasico') {
        switch (this.inputDataForm.value.summerConsumption) {
          case 200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-3-m-16513?search=OrduStorage+3+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-3-m-16513?search=OrduStorage+3+M#attr=';
            }
            break;
          case 300:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-3-m-16513?search=OrduStorage+3+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-3-m-16513?search=OrduStorage+3+M#attr=';
            }
            break;
          case 400:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-3-6-m-16521?search=OrduStorage+3+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/solucion-autoconsumo-residencial-monofasico-3-6kw-con-baterias-3e-16521?search=OrduStorage+3+M#attr=';
            }
            break;
          case 600:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-5-m-16524?search=OrduStorage+5+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/solucion-autoconsumo-residencial-monofasico-5kw-con-baterias-4e-16524?search=OrduStorage+5+M#attr=';
            }
            break;
          case 800:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-6-m-18518?page=2&search=OrduStorage+6+M#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-6-m-18518?page=2&search=OrduStorage+6+M#attr=';
            }
            break;
          case 1000:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
          case 1200:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
          case 1500:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
          case 1800:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/pt/contacto';
            }
            break;
        }
      }
      if (this.inputDataForm.value.powerOption === 'trifasico') {
        switch (this.inputDataForm.value.summerConsumption) {
          case 200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            break;
          case 300:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            break;
          case 400:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            break;
          case 600:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-5-t-18514?search=OrduStorage-S+5+T#attr=';
            }
            break;
          case 800:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-6-t-18515?search=OrduStorage-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-6-t-18515?search=OrduStorage-S+6+T#attr=';
            }
            break;
          case 1000:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-8-t-18516?search=OrduStorage-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-8-t-18516?search=OrduStorage-S+6+T#attr=';
            }
            break;
          case 1200:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-8-t-18516?search=OrduStorage-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-8-t-18516?search=OrduStorage-S+6+T#attr=';
            }
            break;
          case 1500:
            if (this.language === 'es') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/es/shop/product/pack-ordustorage-s-10-t-18517?page=2&search=OrduStorage-S+6+T#attr=';
            }
            if (this.language === 'por') {
              this.kitShopUrl =
                'https://www.suministrosorduna.com/pt/shop/product/pack-ordustorage-s-10-t-18517?page=2&search=OrduStorage-S+6+T#attr=';
            }
            break;
          case 1800:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            break;
          default:
            if (this.language === 'es') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            if (this.language === 'por') {
              this.kitShopUrl = 'https://www.suministrosorduna.com/contacto';
            }
            break;
        }
      }
    }
    // if (this.numRows === 0) {
    //   this.imagename = 'contactanos';
    //   template = this.Surface;
    //   this.numpanels = 0;
    //   this.numRows = 0;
    //   this.kitSurfaceCoplanar = 0;
    //   this.kitSurfaceTrisole = 0;
    //   this.kitShopUrl = '';
    //   this.structCoplanarShopUrl = '';
    //   this.structTrisoleShopUrl = '';
    // }

    // let view = template.createEmbeddedView(null);
    // this.kitContainer.insert(view);
    this.gotoKitShop();
  }

  gotoKitShop() {
    const url = this.kitShopUrl;
    window.open(url, '_blank');
  }

  gotoStructShop() {
    let url = '';
    if (this.inputDataForm.value.roofType === 'plana')
      url = this.structTrisoleShopUrl;
    if (this.inputDataForm.value.roofType === 'inclinada')
      url = this.structCoplanarShopUrl;
    window.open(url, '_blank');
  }

  onButtonClick(language: string) {
    this.inputDataForm = this.formBuilder.group({
      summerConsumption: [200, Validators.required],
      powerOption: ['', Validators.required],
      saveOption: ['', Validators.required],
      installOption: [''],
    });
    this.language = language;
  }
}
