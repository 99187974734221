import { Component, OnInit, Input, Self, Optional, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-pplus-input-switch',
  templateUrl: './pplus-input-switch.component.html',
  styleUrls: ['./pplus-input-switch.component.scss']
})
export class PplusInputSwitchComponent implements OnInit, ControlValueAccessor {

  @Input() disabled: boolean;
  @Input() style: any;
  @Input() styleClass: string;

  @Input() leftIcon: string;
  @Input() rightIcon: string;

  @Input() options: any[];


  @Output() onOptionClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  value: any;
  selectedType: any;
  defaultIconValue = 'fa fa-circle no-icon-switch';

  constructor(
    @Self() @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.options = [
      { rate: '200 - 300', value: '200 - 300' },
      { rate: '400 - 500', value: '400 - 500' },
    ];


    this.disabled = false;
    this.styleClass = '';
    this.leftIcon = this.defaultIconValue;
    this.rightIcon = this.defaultIconValue;
  }

  onChangeFn = (_: any) => { };

  ngOnInit() {
    this.setDefaultIconValue();
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.selectedType = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    //this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChangeFn = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChangeEvent() {
    this.onChangeFn(this.selectedType);
    this.onChange.emit(this.selectedType);
  }

  setDefaultIconValue() {
    if (this.leftIcon == null) {
      this.leftIcon = this.defaultIconValue;
    }
    if (this.rightIcon == null) {
      this.rightIcon = this.defaultIconValue;
    }
  }

  private onTouched() { }

}
