<div class="switch-input">
    <p-selectButton [(ngModel)]="selectedType" [ngClass]="styleClass" [disabled]="disabled" [options]="options" (onChange)="onChangeEvent()">
        <ng-template ngFor let-item [ngForOf]="options" let-i="index">
            <div *ngIf="i == 0" class="switch-button">
                <div class="switch-icon">
                    <i [ngClass]="leftIcon"></i>
                </div>
                <div class="switch-text">
                    <span>{{item.label}}</span>
                </div>

            </div>
            <div *ngIf="i > 0" class="switch-button">
                <div class="switch-text">
                    <span>{{item.label}}</span>
                </div>
                <div class="switch-icon">
                    <i [ngClass]="rightIcon"></i>
                </div>
            </div>
            <div>
            </div>
        </ng-template>
    </p-selectButton>
</div>